.layout_container {
    height: 100%;
}
/* 头部 */
.header_container {
    height: 90px;
    width: 100%;
    /* box-shadow:0px 2px 25px 0px #C1CEDE;
    box-shadow: 0px 2px 25px 0px #4973A5; */
    background-color: #FFF;
    padding: 0 20px;
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; */
}
.header_content {
    /* width: 960px; */
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.header_right_container {
    display: flex;
    margin: 0;
}
.header_right_item {
    padding: 12px 0;
    margin: 0 18px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.header_right_item:hover {
    color: rgba(0, 0, 0, 0.8);
}
.header_right_item_active {
    color: #000;
    position: relative;
}
.header_right_item_active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    border-radius:17px;
    background: linear-gradient(270deg,rgba(103,175,255,1) 0%,rgba(76,134,255,1) 100%);
}


/* 移动端样式 */
.menu_board {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.menu_white_board {
    width: 50%;
    height: 100%;
    background-color: #FFF;
    position: absolute;
    right: 0;
}
.menu_p {
    height: 56px;
    width: 100%;
    line-height: 56px;
    color: #8A8A8A;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0;
}
.menu_ul > li {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color:rgba(0,0,0,0.85);
}
.menu_active_li {
    background-color: #F3F8FF;
    border-left: 4px solid #4A90E2;
}


@media only screen and (max-width: 960px) {
    .header_logo {
        width: 100px;
    }
    .header_content {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header_right_container {
        display: none;
    }
    .header_menu_logo {
        display: block !important;
    }
    .header_container {
        height: 50px;
    }
}