.technology_support_container {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.technology_support_input_div {
    width: 90%;
    padding: 10px 12px;
    border: 1px solid lightgray;
    border-radius: 4px;
}
.technology_support_input {
    min-height: 300px;
    width: 100%;
    outline: none;
    border: none;
    resize: none;
}
.upload_submit {
    margin-top: 20px;
    width: 90%;
    height: 35px;
    border-radius: 4px;
    background-color: #4A90E2;
    text-align: center;
    line-height: 35px;
    color: #FFF;
}